<template>
  <div class="pageBox">
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="name"
          label="作品名称"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.title | formatting }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="时间"  align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatting }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <div>
              <span class="blueText" @click="restore(scope.row)"> 还原 </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="btnBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total,prev, pager, next"
        :page-size="listQuery.pageSize"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listQuery: {
        pageNo: 1, // 当前页
        pageSize: 10, // 每页显示数量
      },
      total: 0, // 总条数
      tableData: [], //垃圾箱数据列表
    };
  },
  filters: {
    //普通格式化
    formatting(value) {
      if (value) {
        return value;
      } else {
        return "暂无";
      }
    },
  },
  created() {
    this.getList(); //获取列表
  },
  mounted() {},

  methods: {
    // 获取垃圾箱列表
    getList() {
      this.$api.filmsFilmsInfo
        .getTrashCanList(this.listQuery)
        .then((res) => {
          // console.log("数据列表", res);
          if (res.result.records.length > 0) {
            this.tableData = JSON.parse(JSON.stringify(res.result.records));
            this.total = res.result.total;
          } else {
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
    //还原
    restore(row) {
      this.$confirm("确定还原该作品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.filmsFilmsInfo
            .reductionTrashCan({ id: row.id })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "还原成功",
                  type: "success",
                });
                this.$emit("refreshTab");
                this.getList();
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message,
                type: "error",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //分页
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList();
    },
    //当前页
    handleCurrentChange(val) {
      this.listQuery.pageNo = val;
      this.getList();
    },
  },
};
</script>

<style  scoped>
.pageBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.tableBox {
  padding: 10px;
}
.blueText {
  color: rgb(100, 188, 216);
  cursor: pointer;
}
.orangeText {
  color: rgb(253, 120, 76);
  cursor: pointer;
}
.btnBox {
  width: 100%;
  padding-right: 20px;
  position: absolute;
  bottom: 68px;
  left: 0;
  height: 70px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>