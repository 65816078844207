<template>
  <div class="boxAll">
    <div class="newsContainer">
      <first ref="first" v-show="nowPage == 1" @changePage="changePage"></first>
      <second
        ref="second"
        :firstParams="firstParams"
        v-if="nowPage == 2"
        @changePage2="changePage2"
        @issuseOk="issuseOk"
      ></second>
    </div>
  </div>
</template>

<script>
import first from "./first/index.vue"; //第一步
import second from "./second/index.vue"; //第二步
export default {
  components: {
    first, //第一步
    second, //第二步
  },
  data() {
    return {
      nowPage: 1, //第几步 1是第一步
      params: {}, //参数
      firstParams: {}, //第一步参数
      secondParams: {}, //第二步参数
    };
  },

  mounted() {},

  methods: {
    //初始化
    init(params) {
      this.params = JSON.parse(params);
      this.getDetail(this.params.id);
    },
    //获取蜘影详情
    getDetail(id) {
      this.$api.filmsFilmsInfo.getInfo(id).then((res) => {
        // console.log("详情",res)
        this.nowPage = 1;
        this.$nextTick(() => {
          this.$refs.first.init(JSON.stringify(res.result));
        });
      });
    },
    //切换第一步第二步
    changePage(num, val) {
      // console.log(num)
      this.nowPage = num;
      if (val) {
        this.firstParams = JSON.parse(val);
      }
    },
    //第二步前往第一步
    changePage2(num, val) {
      this.nowPage = num;
      if (val) {
        this.secondParams = JSON.parse(val);
      }
    },
    //发布成功
    issuseOk() {
      this.$emit("refreshTab");
      this.$emit("changeTab");
    },
  },
};
</script>

<style scoped>
.newsContainer {
  /* height: 539px; */
  width: 100%;
  height: 100%;
}
.boxAll{
  height: 100%;
}
</style>