<template>
  <div>
    <div class="previewBox" v-if="show">
      <div class="phoneBox">
        <div class="topTitle">播放厅</div>
        <div class="videoBox">
          <video :src="nowVideo.filename" controls class="videoItem"></video>
          <div class="videoBtn">
            <div class="btnItem">
              <i class="iconfont icon-zhuanfa iconStyle m3R"></i>
              <span class="iconStyle">转发</span>
            </div>
            <div class="btnItem">
              <i
                class="iconfont icon-changyonggoupiaorenbianji iconStyle m3R"
              ></i>
              <span class="iconStyle">31</span>
            </div>
            <div class="btnItem">
              <i class="iconfont icon-pingfenxiangguanli iconStyle m3R"></i>
              <span class="iconStyle">打分</span>
            </div>
          </div>
        </div>
        <!-- 剧集 -->
        <div class="episodeBoxAll">
          <div class="episodeBox">
            <div
              class="episodebtn"
              v-for="(item, index) in videoList"
              @click="changeSelect(index)"
              :key="index"
              :class="selectIndex == index ? 'activeEpisodebtn' : ''"
            >
              {{ Number(item.sort) }}
              <i
                class="iconfont icon-tongji tongjiStyle"
                v-if="selectIndex == index"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <i class="el-icon-close closeIcon" @click="closeShow"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false, //是否隐藏
      videoList: [], //视频列表
      nowVideo: {}, //当前的视频
      selectIndex: 0, //选中的
    };
  },

  mounted() {},

  methods: {
    //打开 显示
    openDialog(val) {
      if (val) {
        this.videoList = JSON.parse(val);
        this.nowVideo = this.videoList[0];
        console.log(this.videoList);
      }
      this.show = true;
    },
    //关闭页面
    closeShow() {
      this.show = false;
    },
    //切换视频
    changeSelect(i) {
      this.selectIndex = i;
      this.nowVideo = this.videoList[i];
    },
  },
};
</script>

<style scoped>
.previewBox {
  width: 100%;
  position: absolute;
  top: -60px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phoneBox {
  width: 250px;
  height: 441px;
  background: #fff;
  position: relative;
  top: -30px;
}
.closeIcon {
  font-size: 26px;
  color: #ffffff;
  position: relative;
  top: -10px;
  cursor: pointer;
}
.topTitle {
  background: #4db4d4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.videoBox {
  padding: 10px;
  border-bottom: 3px solid rgb(245, 247, 250);
}
.videoItem {
  width: 230px;
  height: 130px;
}
.videoBtn {
  margin-top: 10px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnItem {
  display: flex;
  align-items: center;
  color: #7b7d80;
}
.iconStyle {
  font-size: 12px;
}
.m3R {
  margin-right: 3px;
}
.episodeBoxAll {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.episodeBox {
  width: 235px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  padding-right: 5px;
}
.episodebtn {
  width: 40px;
  height: 30px;
  position: relative;
  background: rgb(245, 247, 250);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 13px;
  margin-right: 6px;
  margin-bottom: 10px;
  cursor: pointer;
}
.activeEpisodebtn {
  background: rgb(237, 247, 250);
  color: rgb(100, 189, 217);
  position: relative;
}
.tongjiStyle {
  font-size: 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  color: rgb(100, 189, 217);
}
</style>