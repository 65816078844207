<template>
  <div class="boxAll">
    <div class="uploadVideoBoxadVideoBox">
      <div class="leftTab">
        <div class="leftTabHead">
          <div class="headName">上传视频</div>
        </div>
        <div class="tabBox">
          <div class="tabItem pointer" v-for="item in tabList" :key="item.id"
            @click="changeSelect(item)" :class="item.id == selectId ? 'activeBg' : ''">
            <div class="itemName">
              {{ item.name }}
            </div>
            <div class="itemNum" v-if="item.num > 0">
              {{ item.num }}
            </div>
            <div class="clearBox" v-if="item.id == 4" @click.stop="clearDustbin">
              [清空]
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="rightBox">
          <div class="rightTitle">
            <div class="rightTitleName">
              {{ selectTitle }}
              <span class="titleNum" v-if="tabList[selectId - 1].num > 0">{{
                tabList[selectId - 1].num
              }}</span>
            </div>
          </div>
          <div class="container" ref="loadingBox">
            <!-- 发布蜘影新闻 -->
            <news ref="news" v-if="selectId == 1" @refreshTab="init" @changeTab="change3Tab"></news>
            <!-- 发布蜘影剧 -->
            <tv ref="tv" v-if="selectId == 2" @refreshTab="init" @changeTab="change3Tab"></tv>
            <!-- 发布蜘识课程 -->
            <course ref="course" v-if="selectId == 5" @refreshTab="init" @changeTab="change3Tab">
            </course>
            <!-- 已发作品 -->
            <already-sent ref="alreadySent" v-if="selectId == 3" @refreshTab="init"
              @edit="edit"></already-sent>
            <!-- 垃圾箱 -->
            <dustbin ref="dustbin" v-if="selectId == 4" @refreshTab="init"></dustbin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from "./news/index.vue"; //发布蜘影新闻
import tv from "./tv/index.vue"; //发布蜘影剧
import course from "./course/index.vue"; //发布蜘识课程
import alreadySent from "./alreadySent/index.vue"; //已发作品
import dustbin from "./dustbin/index.vue"; //垃圾箱
export default {
  name: "uploadVideo",
  components: {
    news, //发布影视新闻
    tv, //发布影视剧
    course, //发布蜘识课程
    alreadySent, //已发作品
    dustbin, //垃圾箱
  },
  data() {
    return {
      tabList: [
        //左侧tab列表
        { id: 1, name: "发布蜘影新闻", num: 0 },
        { id: 2, name: "发布蜘影剧", num: 0 },
        { id: 5, name: "发布蜘识课程", num: 0 },
        { id: 3, name: "已发作品", num: 0 },
        { id: 4, name: "垃圾箱", num: 0 },
      ],
      selectId: 1, //选中的id
      selectTitle: "发布蜘影新闻", //选中的标题
    };
  },
  created() {
    this.init(); //获取列表
  },
  mounted() { },
  methods: {
    //初始化请求
    init() {
      this.getAlreadySentList(); //获取已发作品列表
      this.getDustbinList(); //获取垃圾箱列表
    },
    //获取已发作品列表
    getAlreadySentList() {
      this.$api.filmsFilmsInfo
        .getMemberFilmList()
        .then((res) => {
          // console.log("数据列表", res);
          if (res.result.records.length > 0) {
            this.tabList[3].num = res.result.records.length;
          } else {
            this.tabList[3].num = 0;
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
    //获取垃圾箱列表
    getDustbinList() {
      this.$api.filmsFilmsInfo
        .getTrashCanList()
        .then((res) => {
          // console.log("数据列表", res);
          if (res.result.records.length > 0) {
            this.tabList[4].num = res.result.records.length;
          } else {
            this.tabList[4].num = 0;
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
    //改变选择
    changeSelect(val) {
      this.selectId = val.id;
      this.selectTitle = val.name;
    },
    //发布蜘影剧成功 跳转到已发列表
    change3Tab() {
      this.selectId = 3;
      this.selectTitle = "已发作品";
    },
    //发布蜘影剧成功 跳转到已发列表
    change2Tab() {
      this.selectId = 2;
      this.selectTitle = "发布蜘影剧";
    },
    //清空垃圾箱
    clearDustbin() {
      this.$confirm("确定清空垃圾箱？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            target: this.$refs.loadingBox,
            lock: false,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255,.7)",
          });
          this.$api.filmsFilmsInfo
            .delTrashCan()
            .then((res) => {
              loading.close();
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "清空成功!",
                });
                //如果是在垃圾箱页面
                if (this.selectId == 4) {
                  this.$nextTick(() => {
                    this.$refs.dustbin.getList();
                  });
                }

                this.init();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            })
            .catch((err) => {
              loading.close();
              this.$message({
                type: "error",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空垃圾箱",
          });
        });
    },
    //编辑修改
    edit(val) {
      // console.log(val.type)
      //蜘影类型 0新闻 1蜘影剧 2蜘影台 3蜘识课堂
      if (val.type === 1) {
        this.selectId = 2;
        this.selectTitle = "编辑蜘影剧";
        this.$nextTick(() => {
          this.$refs.tv.init(JSON.stringify(val));
        });
      }
      if (val.type === 0) {
        this.selectId = 1;
        this.selectTitle = "编辑蜘影新闻";
        this.$nextTick(() => {
          this.$refs.news.init(JSON.stringify(val));
        });
      }
      if (val.type === 3) {
        this.selectId = 5;
        this.selectTitle = "编辑蜘识课程"
        this.$nextTick(() => {
          this.$refs.course.init(JSON.stringify(val));
        })
      }
    },
  },
};
</script>

<style scoped>
.boxAll {
  width: 100%;
}
.container {
  flex: 1;
  position: relative;
  flex-shrink: 0;
}
.uploadVideoBoxadVideoBox {
  min-width: 760px;
  width: 100%;
  height: 100%;
  display: flex;
}
.leftTab {
  width: 253px;
  background: #ffffff;
  border-right: 1px solid #dcdfe6;
  height: 100%;
}
.leftTabHead {
  width: 253px;
  height: 60px;
  background: #f5f7fa;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdfe6;
}
.tabBox {
  width: 253px;
}
.tabItem {
  width: 253px;
  height: 57px;
  background: #ffffff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313233;
  border-bottom: 1px solid #dcdfe6;
  position: relative;
}
.itemName {
  padding-left: 10px;
}
.headName {
  padding-left: 10px;
  font-weight: 600;
}
.pointer {
  cursor: pointer;
}
.activeBg {
  background: #f5f7fa;
}
.right {
  width: 100%;
}
.rightBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rightTitle {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  border-bottom: 1px solid #dcdfe6;
  flex-shrink: 0;
}
.rightTitleName {
  padding-left: 10px;
  font-weight: 600;
}
.clearBox {
  padding-right: 10px;
  height: 57px;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  color: #ff6632;
}
.itemNum {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b7d80;
  margin-left: 8px;
}
.titleNum {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b7d80;
  margin-left: 8px;
}
</style>