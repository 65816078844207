<template>
  <div class="newsContainer">
    <first ref="first" v-show="nowPage == 1" :canPublish="canPublish" @changePage="changePage">
    </first>
    <second ref="second" v-if="nowPage == 2" :firstParams="firstParams" @changePage2="changePage2"
      @issuseOk="issuseOk"></second>
  </div>
</template>

<script>
import first from "./first/index.vue"; //第一步
import second from "./second/index.vue"; //第二步
export default {
  components: {
    first, //第一步
    second, //第二步
  },
  data() {
    return {
      canPublish: true, //是否能发布：判断是否开通蜘识课堂和付费蜘影
      nowPage: 1, //第几步 1是第一步
      params: {}, //参数
      firstParams: {}, //第一步参数
      secondParams: {}, //第二步参数
    };
  },
  mounted() {
    this.$api.filmsFilmsInfo.openClassroomRecord().then(res => {
      if (!res.result || !res.result.id) {
        this.canPublish = false
        this.$alert('您当前未开设蜘识课堂，请先在蜘影空间开通蜘识课堂和个人资料开通付费蜘影。', '提示', {
          confirmButtonText: '知道了',
          center: true
        });
      } else if (res.result.auditStatus == 1) {
        this.$api.member.currentUserConfigDetails().then(res => {
          if (res.result && res.result.paidFilms == 0) {
            this.canPublish = false
            this.$alert('您当前未开通付费蜘影，请先在个人资料开通，否则无法收费。', '提示', {
              confirmButtonText: '知道了',
              center: true
            });
          }
        })
      } else if (res.result.auditStatus == 2) {
        this.canPublish = false
        this.$alert('您申请开设蜘识课堂未通过，请在蜘影空间重新提交申请。', '提示', {
          confirmButtonText: '知道了',
          center: true
        });
      } else {
        this.canPublish = false
        this.$alert('您申请的开设蜘识课堂审核中，请耐心等待。', '提示', {
          confirmButtonText: '知道了',
          center: true
        });
      }
    })
  },
  methods: {
    //初始化
    init(params) {
      this.params = JSON.parse(params);
      this.getDetail(this.params.id);
    },
    //获取蜘影详情
    getDetail(id) {
      this.$api.filmsFilmsInfo.getInfo(id).then((res) => {
        // console.log("详情",res)
        this.nowPage = 1;
        this.$nextTick(() => {
          this.$refs.first.init(JSON.stringify(res.result));
        });
      });
    },
    //切换第一步第二步
    changePage(num, val) {
      console.log(num);
      this.nowPage = num;
      if (val) {
        this.firstParams = JSON.parse(val);
      }
    },
    //第二步前往第一步
    changePage2(num, val) {
      this.nowPage = num;
      if (val) {
        this.secondParams = JSON.parse(val);
      }
    },
    //发布成功
    issuseOk() {
      this.$emit("refreshTab");
      this.$emit("changeTab");
    },
  },
};
</script>

<style scoped>
.newsContainer {
  height: 100%;
  width: 100%;
}
</style>