<template>
  <div class="secondtBox" ref="loadingBox">
    <!-- 步骤 -->
    <div class="top">
      <div class="topItem m10R" :class="nowStatus > 0 ? 'activeColor' : ''">
        <div class="topItemNumBox m5R" :class="nowStatus > 0 ? 'activeBg' : ''">
          1
        </div>
        <div class="topItemName">填写信息</div>
      </div>
      <div class="topItem greyColor m10R">——</div>
      <div class="topItem" :class="nowStatus == 2 ? 'activeColor' : ''">
        <div class="topItemNumBox m5R" :class="nowStatus == 2 ? 'activeBg' : ''">
          2
        </div>
        <div class="topItemName">设定标签</div>
      </div>
    </div>
    <!-- 标签内容 -->
    <div class="contaner">
      <div class="contanerName">设定标签</div>
      <div class="contanerDesc">设定标签有利于视频搜索</div>
      <div class="tagBox">
        <div class="tagItem" v-for="(item, index) in tagList" :key="index">
          <div class="tagName">
            {{ item.name }}
          </div>
          <div class="tagPunctuation">:</div>
          <div class="tagVal" :class="item.blurStatus ? 'isBlurBox' : ''">
            <span v-if="item.blurStatus">{{ item.value }}</span>
            <input type="text" class="valueInput" v-if="!item.blurStatus" placeholder="请输入标签内容"
              v-model="item.value" @blur="inputBlur(item)" />
          </div>
          <img src="@/assets/images/ic_tuichu@3x.png" class="closeIcon pointer" alt=""
            v-if="item.blurStatus" @click="delTag(item)" />
        </div>
      </div>
    </div>
    <!-- 下一步按钮 -->
    <div class="nextBtnBox">
      <div class="lastBox">
        <div class="lastBtn pointer" @click="last">
          {{ "<<" }}
        </div>
      </div>
      <div class="btnBox">
        <div class="nextBtn pointer" @click="send">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstParams: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      nowStatus: 2, //当前状态
      tagList: [
        { name: "标签1", value: "", blurStatus: false },
        { name: "标签2", value: "", blurStatus: false },
        { name: "标签3", value: "", blurStatus: false },
        { name: "标签4", value: "", blurStatus: false },
        { name: "标签5", value: "", blurStatus: false },
      ],
      form: {
        type: 3, //蜘影类型 0新闻 1蜘影剧 2蜘影台 3蜘识课堂
        keyWord: "", //关键字
      },
    };
  },
  created() {
    if (this.firstParams.keyWord != '' && this.firstParams.keyWord) {
      this.form.keyWord = this.firstParams.keyWord
      let arr = this.firstParams.keyWord.split(',')
      arr.forEach((item, index) => {
        this.tagList[index].value = item
        this.tagList[index].blurStatus = true
      })
    }
    // console.log(this.firstParams);
    this.form = Object.assign(this.form, this.firstParams);
  },
  mounted() { },
  methods: {
    //上一步
    last() {
      this.$emit("changePage2", 1);
    },
    //删除tag标签
    delTag(val) {
      val.value = "";
      val.blurStatus = false;
      this.computedKeyWord()
    },
    //计算关键字 compouted监听不到
    computedKeyWord() {
      let arr = [];
      this.tagList.forEach((item) => {
        if (item.blurStatus) {
          arr.push(item.value);
        }
      });
      this.form.keyWord = arr.join(",");
    },
    //输入框失去焦点
    inputBlur(val) {
      if (val.value == "") {
        val.blurStatus = false;
        this.$set(val, 'blurStatus', false)
      } else {
        val.blurStatus = true;
        this.$set(val, 'blurStatus', true)
      }
      //计算关键字
      this.computedKeyWord();
    },
    //发送
    send() {
      // console.log(this.form)
      this.form.list.forEach((item) => {
        item.sort = Number(item.sort);
      });
      //有id说明是编辑
      if (this.form.id) {
        const loading = this.$loading({
          target: this.$refs.loadingBox,
          lock: false,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255,.7)",
        });
        this.$api.filmsFilmsInfo
          .editFilm(this.form)
          .then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("issuseOk");
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: err.message,
              type: "error",
            });
          });
      } else {
        const loading = this.$loading({
          target: this.$refs.loadingBox,
          lock: false,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255,.7)",
        });
        this.$api.filmsFilmsInfo
          .addInfo(this.form)
          .then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              this.$emit("issuseOk");
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: err.message,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.secondtBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.topItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abaeb3;
}
.topItemNumBox {
  width: 18px;
  height: 18px;
  background: #c4c7cc;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m10R {
  margin-right: 10px;
}
.m5R {
  margin-right: 5px;
}
.activeColor {
  color: #4db4d4;
}
.activeBg {
  background: #4db4d4;
}
.greyColor {
  color: #dcdfe6;
}
.firstContent {
  width: 100%;
}
.contentNameBox {
  padding: 10px;
  width: calc(100%-20px);
  border-bottom: 1px solid #dcdfe6;
}
.uploadBox {
  padding: 10px;
  width: calc(100%-20px);
}
.contentName {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #313233;
  margin-bottom: 10px;
}
.contentInput {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
}
.contentInput:focus {
  border: none;
}
.uploadDesc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abaeb3;
}
.img_upload {
  width: 32px;
  height: 32px;
}
.remark {
  font-size: 8px;
  color: #7a7c80;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.uploadComponent {
  margin-top: 20px;
  width: 100%;
  display: flex;
  height: 80px;
  background: #f5f7fa;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.uploadBoxImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoBox {
  width: 150px;
  height: 80px;
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
}
.videoItem {
  width: 100%;
  height: 100%;
}
.closeIcon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
.videoList {
  width: calc(100%-20px);
  padding: 0 10px;
  height: 110px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}
.nextBtnBox {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  border-top: 1px solid #dcdfe6;
  /* position: absolute; */
  /* left: 0;
  bottom: 68px; */
  background: #fff;
}
.nextBtn {
  width: 70px;
  height: 28px;
  background: #4db4d4;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.activeBlueBg {
  background: #4db4d4;
}
.pointer {
  cursor: pointer;
}
.btnBox {
  padding-left: 10px;
}
.lastBox {
  padding-left: 10px;
}
.lastBtn {
  width: 48px;
  height: 28px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #dcdfe6;
  color: #313233;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contanerName {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #313233;
  padding-left: 10px;
}
.contanerDesc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abaeb3;
  padding-left: 10px;
  color: #abaeb3;
  margin-top: 10px;
  margin-bottom: 20px;
}
.tagItem {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 16px;
}
.tagPunctuation {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313233;
  margin-right: 8px;
  margin-left: 4px;
}
.tagName {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313233;
}
.valueInput {
  width: 85px;
  height: 20px;
  border: none;
  outline: none;
  font-size: 14px;
}
.valueInput:focus {
  border: none;
}
.tagVal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  border: 1px solid #abadb3;
}
.isBlurBox {
  color: #4db4d4;
  border: 1px solid #4db4d4;
}
</style>