<template>
    <div class="chat-window" ref="loadingBox">
        <div class="chat-container">
            <!-- 步骤 -->
            <div class="top">
                <div class="topItem m10R" :class="nowStatus > 0 ? 'activeColor' : ''">
                    <div class="topItemNumBox m5R" :class="nowStatus > 0 ? 'activeBg' : ''">
                        1
                    </div>
                    <div class="topItemName">填写信息</div>
                </div>
                <div class="topItem greyColor m10R">——</div>
                <div class="topItem" :class="nowStatus == 2 ? 'activeColor' : ''">
                    <div class="topItemNumBox m5R" :class="nowStatus == 2 ? 'activeBg' : ''">
                        2
                    </div>
                    <div class="topItemName">设定标签</div>
                </div>
            </div>
            <el-form :model="form" label-position="top" size="small" class="content">
                <el-form-item label="蜘影剧名称">
                    <el-input v-model="form.title" class="input-style"
                              placeholder="请输入20个汉字以内的作品名称"></el-input>
                </el-form-item>
                <!-- <el-form-item label="表情介绍">
                  <el-input
                    v-model="model.expressionIntroduce"
                    class="input-style"
                    maxlength="80"
                    show-word-limit
                    placeholder="请填写 80 个汉字以内的表情介绍"
                  ></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="表情主图类型">
                    <el-checkbox :true-label="0" :false-label="1" v-model="model.type">动态图</el-checkbox>
                    <el-checkbox :true-label="1" :false-label="0" v-model="model.type">静态图</el-checkbox>
                </el-form-item> -->
                <el-form-item label="蜘影剧封面图片" v-if="false">
                    <div class="uploadExplain">
                        JPG 格式，180 × 180像素，大小不超过500kb
                    </div>
                    <!-- 上传视频 -->
                    <el-upload class="avatar-uploader" :action="host" :show-file-list="false"
                               :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :data="ossForm"
                               :multiple="false">
                        <img v-if="form.videoImageUrl" :src="form.videoImageUrl" class="avatar"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上传剧集">
                    <div class="uploadExplain">视频格式为16:9</div>
                    <el-upload class="upload-demo" drag :action="host" :show-file-list="false"
                               :on-success="handleAvatarSuccess1" :on-error="handleAvatarError"
                               :before-upload="beforeAvatarUpload1" :data="ossForm" :multiple="false">
                        <img src="@/assets/images/ic_shangchuan@2x.png"/>
                        <div class="el-upload__text">点击上传或将视频拖动到此区域</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="videoBox">
                <div class="videoItemBox" v-for="(item, index) in form.list" :key="index">
                    <div class="videoItemTop">
                        <div class="videoItemTopSort">
                            {{ item.sort }}
                        </div>
                        <img src="@/assets/images/ic_tuichu@3x.png" class="closeIcon pointer" alt=""
                             @click="delVideo(index)"/>
                    </div>
                    <div class="videoItemItem">
                        <video :src="item.filename" controls class="videoItem"
                               :ref="'videoItem' + item.filename"></video>
                    </div>
                </div>
            </div>
        </div>
        <!-- 视频封面canvas -->
        <canvas id="mycanvas" style="display: none"></canvas>
        <div class="nextBtnBox">
            <div class="nextBtn pointer" :class="nextStatus ? 'activeBlueBg' : ''" @click="next">
                下一步
            </div>
            <div class="preview" @click="preview">预览</div>
        </div>

        <!-- 预览弹窗 -->
        <preview-dialog ref="previewDialog"></preview-dialog>
    </div>
</template>

<script>
    import previewDialog from "./previewDialog/index.vue";
    import api from "@/api/api";
    import axios from "axios";

    export default {
        components: {
            previewDialog,
        },
        data() {
            return {
                nowStatus: 1, //当前状态
                loading: null, //加载状态
                form: {
                    //请求参数
                    title: "", //蜘影剧名称
                    videoImageUrl: null, //蜘影剧封面图片
                    list: [], //剧集
                },
                ossForm: {}, //oss配置
                host: "", //上传地址
                fileName: '', //文件名称
            };
        },
        created() {
        },
        watch: {
            //监听图片列表的变化 动态更新图片命名和索引
            detailList(newValue) {
                newValue.forEach((item, index) => {
                    this.$set(item, "detailName", "0" + (index + 1));
                    this.$set(item, "sort", index + 1);
                });
            },
        },
        computed: {
            nextStatus() {
                if (
                    this.form.title != "" &&
                    this.form.list.length > 0
                ) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        methods: {
            //初始化 编辑
            init(val) {
                // console.log("=====",val)
                this.form = JSON.parse(val);
                this.form.list.forEach((item) => {
                    item.filename = item.videoUrl;
                    item.sort = item.sort > 9 ? item.sort : "0" + item.sort;
                });
            },
            //下一步
            next() {
                //判断一下是否可以跳转到下一步
                if (!this.nextStatus) {
                    this.$message({
                        message: "请填写完本页内容",
                        type: "warning",
                    });
                    return;
                }
                console.log(this.form);
                this.$emit("changePage", 2, JSON.stringify(this.form));
            },
            //打开预览弹窗
            preview() {
                if (this.form.list.length == 0) {
                    this.$message({
                        message: "您的上传剧集为空",
                        type: "warning",
                    });
                    return;
                }
                let list = JSON.stringify(this.form.list);
                this.$refs.previewDialog.openDialog(list);
            },
            //关闭按钮删除这张卡片
            deleteImgCard(index) {
                //假删除只是列表删除因为和请求的detailList不一样
                this.detailList.splice(index, 1);
            },
            //上传图片成功后 封面图片
            handleAvatarSuccess(res, file) {
                //   this.imageUrl = URL.createObjectURL(file.raw);
                this.form.videoImageUrl = res.result.filename;
            },
            //计算视频序号
            computedSort() {
                this.form.list.forEach((item, index) => {
                    if (index + 1 > 9) {
                        this.$set(item, "sort", index + 1);
                    } else {
                        this.$set(item, "sort", "0" + (index + 1));
                    }
                });
            },
            //删除视频
            delVideo(i) {
                this.form.list.splice(i, 1);
                this.computedSort();
                this.computedCover()
            },
            //上传图片前 封面图片
            beforeAvatarUpload(file) {
                //限制是jpg和500kb
                const isJpg = file.type === "image/jpeg";
                const isSize = (file.size / 1024 / 1024 / 1024) * 1000000 < 500;
                if (!isJpg) {
                    this.$message.error("上传封面只能是 jpg 格式!");
                    return;
                }

                if (!isSize) {
                    this.$message.error("上传封面图片大小不能超过 500kb!");
                    return;
                }
                //promise 构造oss参数
                return new Promise((resolve, reject) => {
                    api.upload
                        .policy()
                        .then((res) => {
                            this.host = res.result.host;
                            this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
                            this.ossForm.callback = res.result.callback;
                            this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
                            this.ossForm.policy = res.result.policy;
                            this.ossForm.signature = res.result.signature;
                            this.ossForm.success_action_status = 200;
                            resolve(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            reject(false);
                        });
                });
            },
            //上传剧集 上传剧集成功
            handleAvatarSuccess1(res, file) {
                this.loading.close();
                var _that = this;
                //获取时长
                var audioElement = new Audio(res.result.filename);
                var duration;
                audioElement.addEventListener("loadedmetadata", () => {
                    duration = audioElement.duration; //时长为秒，小数 182.36
                    //赋值时长
                    res.result.duration = duration;
                    res.result.videoUrl = res.result.filename;
                    _that.form.list.push(res.result);
                    _that.computedSort();
                    _that.computedCover()
                });
            },
            //计算封面
            computedCover() {
                if (this.form.list.length == 0) {
                    this.fileName = "";
                    return;
                }
                this.$nextTick(() => {
                    console.log(this.form.list);
                    let dataURL = "";
                    if (this.form.list.length == 0) {
                        return
                    }
                    let url = this.form.list[0].filename;
                    const video = document.createElement("video");
                    video.setAttribute("crossOrigin", "anonymous"); // 处理跨域
                    video.setAttribute("src", url);
                    video.setAttribute("preload", "auto");
                    video.currentTime = 1; // 这里是截取第几帧,因为我的第一帧是黑色的,所以截取了第1帧
                    video.addEventListener("loadeddata", () => {
                        const canvas = document.createElement("canvas");
                        console.log("video.clientWidth", video.videoWidth); // 视频宽
                        console.log("video.clientHeight", video.videoHeight); // 视频高
                        const width = video.videoWidth || 400; // canvas的尺寸和图片一样
                        const height = video.videoHeight || 240; // 设置默认宽高为  400  240
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext("2d").drawImage(video, 0, 0, width, height); // 绘制canvas
                        dataURL = canvas.toDataURL("image/jpeg"); // 转换为base64
                        // console.log(dataURL);
                        this.base64ChangeFile(dataURL);
                    });
                });
            },
            //base64转file文件格式
            base64ChangeFile(dataURI) {
                console.log(dataURI);
                let fileData = this.$base64toFile(dataURI, new Date().getTime() + "");
                this.customerUploadImage(fileData);
            },
            //自定义上传图片
            customerUploadImage(fileData) {
                api.upload
                    .policy()
                    .then((res) => {
                        this.host = res.result.host;
                        this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
                        this.ossForm.callback = res.result.callback;
                        this.ossForm.key = res.result.dir + "/" + new Date().getTime();
                        this.ossForm.policy = res.result.policy;
                        this.ossForm.signature = res.result.signature;
                        this.ossForm.success_action_status = 200;
                        let formData = new FormData();
                        let fileOfBlob = fileData; // 命名图片名
                        formData.append("OSSAccessKeyId", this.ossForm.OSSAccessKeyId);
                        formData.append("callback", this.ossForm.callback);
                        formData.append("key", this.ossForm.key);
                        formData.append("policy", this.ossForm.policy);
                        formData.append("signature", this.ossForm.signature);
                        formData.append("success_action_status", 200);
                        formData.append("file", fileOfBlob);
                        axios.post(this.host, formData).then(ress => {
                            console.log('地址', ress.data.result.filename)
                            this.form.videoImageUrl = ress.data.result.filename
                        })
                    });
            },
            //上传剧集前 上传剧集成功
            beforeAvatarUpload1(file) {
                //限制格式
                let fileName = file.name || "";
                let ext = fileName.split(".")[fileName.split(".").length - 1];
                if (
                    ext !== "avi" &&
                    ext !== "mp4" &&
                    ext !== "3gp" &&
                    ext !== "mkv" &&
                    ext !== "mpg" &&
                    ext !== "mpeg" &&
                    ext !== "ts" &&
                    ext !== "rmvb" &&
                    ext !== "wmv" &&
                    ext !== "flv" &&
                    ext !== "mov"
                ) {
                    this.$message({
                        message:
                            "只可以上传avi、mp4、3gp、mkv、mpg、mpeg、ts、rmvb、wmv、flv、mov视频格式的文件",
                        type: "warning",
                    });
                    return;
                }
                //promise 构造oss参数
                return new Promise((resolve, reject) => {
                    api.upload
                        .policy()
                        .then((res) => {
                            this.host = res.result.host;
                            this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
                            this.ossForm.callback = res.result.callback;
                            this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
                            this.ossForm.policy = res.result.policy;
                            this.ossForm.signature = res.result.signature;
                            this.ossForm.success_action_status = 200;
                            this.loading = this.$loading({
                                target: this.$refs.loadingBox,
                                lock: false,
                                text: "Loading",
                                spinner: "el-icon-loading",
                                background: "rgba(255, 255, 255,.7)",
                            });
                            resolve(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            reject(false);
                        });
                });
            },
            //编辑 重新赋值
            editData(value) {
                // this.$message({
                //     showClose: true,
                //     message: '如果修改表情名称，则是添加新的表情包',
                //     type: 'warning'
                //   });
                this.fromStatus = true; //设置来自上传列表的编辑
                this.model.expressionName = value.expressionName;
                this.model.expressionIntroduce = value.expressionIntroduce;
                this.model.expressionPoster = value.expressionPoster;
                this.model.type = value.type;
                this.expressionId = value.id.toString();
                value.detailList.forEach((item) => {
                    this.$set(item, "id", item.id.toString());
                });
                this.detailList = value.detailList;
            },
            //上传失败
            handleAvatarError() {
                this.loading.close();
            },
            //清空表单
            clearForm() {
                this.fromStatus = false;
                this.model.expressionName = "";
                this.model.expressionIntroduce = "";
                this.model.expressionPoster = "";
                this.model.type = null;
                this.detailList = [];
                this.model.detailList = [];
            },
        },
    };
</script>

<style scoped>
    .chat-window {
        min-width: 506px;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-left: 1px solid #dcdfe6;
        position: relative;
    }

    .chat-window .chat-title {
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 18px;
        color: #313233;
        letter-spacing: 2px;
        padding: 0 20px;
        background: #ffffff;
        box-sizing: border-box;
        border-bottom: 1px solid #dcdfe6;
    }

    .chat-window .chat-container {
        min-height: 472px;
        height: 100%;
        position: relative;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        overflow-y: auto;
        border-bottom: 1px solid #dcdfe6;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .chat-container .el-steps--horizontal {
        margin-bottom: 20px;
    }

    .el-form--label-top >>> .el-form-item__label {
        padding: 0;
    }

    .next {
        height: 68px;
        text-align: right;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
    }

    .next button {
        width: 70px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .input-style /deep/ input {
        border: none !important;
        padding-left: 0;
    }

    .content-two .img_require p {
        font-size: 12px;
        color: #abaeb3;
        line-height: 20px;
    }

    .content-two {
        padding-right: 20px;
    }

    .content-two .upload-demo {
        margin: 20px 0;
    }

    .content .upload-demo /deep/ .el-upload {
        width: 98%;
    }

    .content .upload-demo {
        margin: 20px 0;
    }

    .content .img_require p {
        font-size: 12px;
        color: #abaeb3;
        line-height: 20px;
    }

    .content-two .upload-demo /deep/ .el-upload {
        width: 100%;
    }

    .content-two .upload-demo /deep/ .el-upload-dragger {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #f5f7fa;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
    }

    .content-two .upload-demo img {
        width: 32px;
        height: 32px;
    }

    .content-two .upload-demo .el-upload__text {
        font-size: 8px;
        color: #7a7c80;
    }

    .content .upload-demo /deep/ .el-upload-dragger {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #f5f7fa;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
    }

    .content .upload-demo img {
        width: 32px;
        height: 32px;
    }

    .content .upload-demo .el-upload__text {
        font-size: 8px;
        color: #7a7c80;
    }

    .imgList {
        display: flex;
        flex-wrap: wrap;
    }

    .imgCardItem {
        width: 120px;
        height: 166px;
        opacity: 1;
        background: #ffffff;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .imgCardItemTop {
        width: 120px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dcdfe6;
    }

    .imgCardItemTopNum {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        margin-left: 10px;
        color: #313233;
    }

    .imgCardItemTopImg {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        flex-shrink: 0;
    }

    .imgContainerBox {
        width: 120px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dcdfe6;
    }

    .imgContainer {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
    }

    .imgCardItemUnder {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgCardItemUnderInput {
        width: 100px;
        border: none;
        font-size: 12px;
        color: #313233;
        background: none;
        outline: none;
        text-align: center;
    }

    .imgCardItemUnderInput:focus {
        border: none;
        text-align: center;
    }

    .imgCardItemUnderInput:blur {
        border: none;
        text-align: center;
    }

    .posterBox {
        width: 240px;
        height: 340px;
        border: 1px solid #dcdfe6;
    }

    .posterBoxTop {
        width: 240px;
        display: flex;
        align-items: center;
        height: 50px;
        justify-content: space-between;
        border-bottom: 1px solid #dcdfe6;
    }

    .posterBoxTopText {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 600;
    }

    .posterBoxTopImg {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 10px;
    }

    .posterContainerBox {
        width: 240px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .posterContainerBoxImg {
        width: 220px;
        height: 220px;
        flex-shrink: 0;
    }

    .avatar-uploader /deep/ .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-left: 20px;
        margin-top: 20px;
    }

    .avatar-uploader /deep/ .el-upload:hover {
        border-color: #409eff;
        margin-left: 20px;
        margin-top: 20px;
    }

    .avatar {
        width: 78px;
        height: 78px;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 78px;
        text-align: center;
    }

    .uploadExplain {
        font-size: 12px;
        color: #ABAEB3;
        margin-top: -10px;
    }

    .top {
        display: flex;
        align-items: center;
        padding: 10px;
        padding-top: 20px;
        margin-bottom: 65px;
    }

    .topItem {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #abaeb3;
    }

    .topItemNumBox {
        width: 18px;
        height: 18px;
        background: #c4c7cc;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .m10R {
        margin-right: 10px;
    }

    .m5R {
        margin-right: 5px;
    }

    .activeColor {
        color: #4db4d4;
    }

    .activeBg {
        background: #4db4d4;
    }

    .greyColor {
        color: #dcdfe6;
    }

    .list {
        width: calc(100% - 20px);
        padding: 0 10px;
        height: 110px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
    }

    .nextBtnBox {
        /* width: calc(100%-20px); */
        width: 100%;
        position: fixed;
        bottom: 0px;
        height: 68px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-top: 1px solid #dcdfe6;
        background: #fff;
        z-index: 99999;
    }

    .nextBtn {
        width: 70px;
        height: 28px;
        background: rgba(77, 180, 212, 0.5);
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .activeBlueBg {
        background: #4db4d4;
    }

    .pointer {
        cursor: pointer;
    }

    .videoBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .videoBox::-webkit-scrollbar {
        width: 0 !important;
    }

    .videoBox {
        -ms-overflow-style: none;
    }

    .videoBox {
        overflow: -moz-scrollbars-none;
    }

    .videoItemBox {
        width: 150px;
        height: 120px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #dcdfe6;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .videoItemTop {
        height: 30px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        border-bottom: 1px solid #dcdfe6;
    }

    .videoItemItem {
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .videoItem {
        width: 130px;
        height: 80px;
    }

    .closeIcon {
        width: 16px;
        height: 16px;
    }

    .videoItemTopSort {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313233;
    }

    .preview {
        width: 56px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px;
        border: 1px solid #dcdfe6;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313233;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 10px;
    }
</style>
