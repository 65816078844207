<template>
  <div class="firstBox" ref="loadingBox">
    <!-- 步骤 -->
    <div class="top">
      <div class="topItem m10R" :class="nowStatus > 0 ? 'activeColor' : ''">
        <div class="topItemNumBox m5R" :class="nowStatus > 0 ? 'activeBg' : ''">
          1
        </div>
        <div class="topItemName">填写信息</div>
      </div>
      <div class="topItem greyColor m10R">——</div>
      <div class="topItem" :class="nowStatus == 2 ? 'activeColor' : ''">
        <div class="topItemNumBox m5R" :class="nowStatus == 2 ? 'activeBg' : ''">
          2
        </div>
        <div class="topItemName">设定标签</div>
      </div>
      <div class="tip">发布蜘识课程前，先在个人资料开通付费蜘影，否则无法收费。</div>
    </div>
    <!-- 蜘识课程名称 -->
    <div style="flex: 1; overflow-y: hidden">
      <div class="firstContent">
        <div class="contentNameBox">
          <div class="contentName">课程名称</div>
          <input v-model="form.title" type="text" class="contentInput" placeholder="请输入课程名称" />
        </div>
        <!-- 分类 -->
        <div class="category-box">
          <div class="category-title">选择课程门类</div>
          <div class="category-grid">
            <div class="category-item" :class="item.itemValue == form.courseType ? 'active' : ''"
              v-for="(item, index) in categoryList" :key="index"
              @click="form.courseType = item.itemValue">{{item.itemText}}</div>
          </div>
        </div>
        <div class="contentNameBox">
          <div class="contentName">课程介绍</div>
          <input v-model="form.courseIntroduce" type="text" class="contentInput"
            placeholder="请输入课程介绍，限200字" />
        </div>
        <!-- 上传组件 -->
        <div class="uploadBox">
          <div class="contentName">上传课程视频</div>
          <div class="uploadDesc">视频限制10分钟，视频格式为16:9</div>
          <div class="uploadComponent">
            <el-upload class="upload-demo" drag :action="host" :show-file-list="false"
              :on-success="handleAvatarSuccess" :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload" :data="ossForm" :multiple="false">
              <img src="@/assets/images/ic_shangchuan@2x.png" />
              <div class="el-upload__text">点击上传或将视频拖动到此区域</div>
            </el-upload>
          </div>
        </div>
        <!-- 视频列表 -->
        <div class="list">
          <div class="videoBox" v-for="(item, index) in form.list" :key="item.filename">
            <video :src="item.filename" controls class="videoItem"
              :ref="'videoItem' + item.filename"></video>
            <img src="@/assets/images/ic_tuichu@3x.png" class="closeIcon" alt=""
              @click="delVideo(index)" />
          </div>
        </div>
        <!-- 视频封面canvas -->
        <canvas id="mycanvas" style="display: none"></canvas>
        <div class="video-cover" v-if="false">
          <div class="contentName">上传视频封面图片</div>
          <div class="video-cover-text">
            JPG格式，180×180像素，大小不超过500KB。
          </div>
          <el-upload class="upload-demo" :action="host" :show-file-list="false"
            :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1" :data="ossForm"
            :multiple="false">
            <img src="@/assets/images/ic_shangchuan@2x.png" class="uploadImages"
              v-if="!form.videoImageUrl" />
            <img :src="form.videoImageUrl" class="uploadArlImages" v-else />
          </el-upload>
        </div>
      </div>
    </div>
    <!-- 下一步按钮 -->
    <div class="nextBtnBox">
      <div class="nextBtn pointer" :class="nextStatus ? 'activeBlueBg' : ''" @click="next">
        下一步
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import axios from "axios";
export default {
  props: {
    canPublish: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      nowStatus: 1, //当前状态
      ossForm: {}, //oss配置
      host: "", //上传地址
      list: [], //视频列表
      loading: null, //加载状态
      imageUrl: "",
      upLoadStatus: true,
      imgSrc: "", //第一个视频第一帧封面的base64
      form: {
        //参数
        list: [], //视频列表
        title: "", //蜘影新闻标题
        courseIntroduce: "", //课程介绍
        videoImageUrl: "", //视频封面
        courseType: "" //课程门类
      },
      fileName: "", //文件名称
      categoryList: [] //课程分类列表
    };
  },
  computed: {
    //下一步状态
    nextStatus() {
      if (this.canPublish && this.form.list.length > 0 && this.form.title != ""
        && this.form.courseIntroduce != "" && this.form.courseType != "") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$api.filmsFilmsInfo.dictList('course_type').then(res => {
      this.categoryList = res.result || []
    })
  },
  methods: {
    //上传图片成功后  封面
    handleAvatarSuccess1(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.videoImageUrl = res.result.filename;
    },
    //上传图片前 封面
    beforeAvatarUpload1(file) {
      this.upLoadStatus = true;
      console.log(file.type);
      //限制是gif和500kb
      const isSize500 = (file.size / 1024 / 1024 / 1024) * 1000000 < 500;
      // const isPng = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      // const isPng = file.type==="image/png";
      const isJpeg = file.type === "image/jpeg";

      if (!isJPG && !isJpeg) {
        this.$message.error("上传视频封面图片只能是 jpg 格式!");
        this.upLoadStatus = false;
        return false;
      }
      if (!isSize500) {
        this.$message.error("上传表情图片大小不能超过 500kb!");
        this.upLoadStatus = false;
        return false;
      }
      let isFlag = true;
      // 调用[限制图片尺寸]函数
      return this.limitFileWH(180, 180, file).then((ress) => {
        if (ress) {
          console.log(111111111);
          //promise 构造oss参数
          return new Promise((resolve, reject) => {
            api.upload
              .policy()
              .then((res) => {
                this.host = res.result.host;
                this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
                this.ossForm.callback = res.result.callback;
                this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
                this.ossForm.policy = res.result.policy;
                this.ossForm.signature = res.result.signature;
                this.ossForm.success_action_status = 200;
                resolve(true);
              })
              .catch((err) => {
                console.log(err);
                reject(false);
              });
          });
        } else {
          return false;
        }
      });
    },
    limitFileWH(E_width, E_height, file) {
      const _this = this;
      let imgWidth = "";
      let imgHight = "";
      const isSize = new Promise(function (resolve, reject) {
        const width = E_width;
        const height = E_height;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          imgWidth = img.width;
          imgHight = img.height;
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return true;
        },
        () => {
          _this.$message.warning({
            message:
              "上传图片的尺寸应为" +
              E_width +
              "*" +
              E_height +
              "，当前上传图片的尺寸为：" +
              imgWidth +
              "*" +
              imgHight,
            btn: false,
          });
          return false;
        }
      );
      return isSize;
    },
    //初始化 编辑
    init(val) {
      // console.log("=====",val)
      this.form = JSON.parse(val);
      this.form.list.forEach((item) => {
        item.filename = item.videoUrl;
        item.sort = item.sort > 9 ? item.sort : "0" + item.sort;
      });
    },
    //上传图片前
    beforeAvatarUpload(file) {
      //限制格式
      let fileName = file.name || "";
      let ext = fileName.split(".")[fileName.split(".").length - 1];
      if (
        ext !== "avi" &&
        ext !== "mp4" &&
        ext !== "3gp" &&
        ext !== "mkv" &&
        ext !== "mpg" &&
        ext !== "mpeg" &&
        ext !== "ts" &&
        ext !== "rmvb" &&
        ext !== "wmv" &&
        ext !== "flv" &&
        ext !== "mov"
      ) {
        this.$message({
          message:
            "只可以上传avi、mp4、3gp、mkv、mpg、mpeg、ts、rmvb、wmv、flv、mov视频格式的文件",
          type: "warning",
        });
        return;
      }

      return new Promise((resolve, reject) => {
        //限制时长
        var url = URL.createObjectURL(file);
        var audioElement = new Audio(url);
        var duration;
        audioElement.addEventListener("loadedmetadata", () => {
          duration = audioElement.duration; //时长为秒，小数，182.36
          if (duration > 600) {
            this.$message({
              message: "视频长度不能超过10分钟",
              type: "warning",
            });
            return;
          }
          //promise 构造oss参数
          api.upload
            .policy()
            .then((res) => {
              this.host = res.result.host;
              this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
              this.ossForm.callback = res.result.callback;
              this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
              this.ossForm.policy = res.result.policy;
              this.ossForm.signature = res.result.signature;
              this.ossForm.success_action_status = 200;
              this.loading = this.$loading({
                target: this.$refs.loadingBox,
                lock: false,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255,.7)",
              });
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              reject(false);
            });
        });
      });
    },
    //视频排序
    computedSort() {
      this.form.list.forEach((item, index) => {
        item.sort = index + 1;
      });
    },
    //清空表单
    clearForm() {
      this.form.list = [];
      this.form.title = "";
      this.fileName = "";
    },
    //上传视频成功后
    handleAvatarSuccess(res, file) {
      this.loading.close();
      var _that = this;
      //获取时长
      var audioElement = new Audio(res.result.filename);
      var duration;
      audioElement.addEventListener("loadedmetadata", () => {
        duration = audioElement.duration; //时长为秒，小数 182.36
        //赋值时长
        res.result.duration = duration;
        res.result.videoUrl = res.result.filename;
        _that.form.list.push(res.result);
        _that.computedSort();
        _that.computedCover();
      });
    },
    //计算封面
    computedCover() {
      if (this.form.list.length == 0) {
        this.fileName = "";
        return;
      }
      this.$nextTick(() => {
        console.log(this.form.list);
        let dataURL = "";
        if (this.form.list.length == 0) {
          return
        }
        let url = this.form.list[0].filename;
        const video = document.createElement("video");
        video.setAttribute("crossOrigin", "anonymous"); // 处理跨域
        video.setAttribute("src", url);
        video.setAttribute("preload", "auto");
        video.currentTime = 1; // 这里是截取第几帧,因为我的第一帧是黑色的,所以截取了第1帧
        video.addEventListener("loadeddata", () => {
          const canvas = document.createElement("canvas");
          console.log("video.clientWidth", video.videoWidth); // 视频宽
          console.log("video.clientHeight", video.videoHeight); // 视频高
          const width = video.videoWidth || 400; // canvas的尺寸和图片一样
          const height = video.videoHeight || 240; // 设置默认宽高为  400  240
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); // 绘制canvas
          dataURL = canvas.toDataURL("image/jpeg"); // 转换为base64
          // console.log(dataURL);
          this.base64ChangeFile(dataURL);
        });
      });
    },
    //base64转file文件格式
    base64ChangeFile(dataURI) {
      console.log(dataURI);
      let fileData = this.$base64toFile(dataURI, new Date().getTime() + "");
      this.customerUploadImage(fileData);
    },
    //自定义上传图片
    customerUploadImage(fileData) {
      api.upload.policy().then((res) => {
        this.host = res.result.host;
        this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
        this.ossForm.callback = res.result.callback;
        this.ossForm.key = res.result.dir + "/" + new Date().getTime();
        this.ossForm.policy = res.result.policy;
        this.ossForm.signature = res.result.signature;
        this.ossForm.success_action_status = 200;
        let formData = new FormData();
        let fileOfBlob = fileData; // 命名图片名
        console.log("=====", fileOfBlob);
        formData.append("OSSAccessKeyId", this.ossForm.OSSAccessKeyId);
        formData.append("callback", this.ossForm.callback);
        formData.append("key", this.ossForm.key);
        formData.append("policy", this.ossForm.policy);
        formData.append("signature", this.ossForm.signature);
        formData.append("success_action_status", 200);
        formData.append("file", fileOfBlob);
        axios.post(this.host, formData).then((ress) => {
          console.log("地址", ress.data.result.filename);
          this.form.videoImageUrl = ress.data.result.filename;
        });
      });
    },
    //上传视频失败
    handleAvatarError(err) {
      this.loading.close();
    },
    //删除视频列表的某个视频
    delVideo(i) {
      this.form.list.splice(i, 1);
      this.computedCover();
    },
    //下一步
    next() {
      //判断一下是否可以跳转到下一步
      if (!this.nextStatus) {
        let message = "请填写课程名称、选择课程门类、填写课程介绍和上传课程视频"
        if (!this.canPublish) {
          message = "发布蜘识课程前，先在个人资料开通付费蜘影，否则无法收费。"
        }
        this.$message({
          message: message,
          type: "warning",
        });
        return;
      }
      //调用上传视频封面图片方法
      this.$emit("changePage", 2, JSON.stringify(this.form));
    },
  },
};
</script>

<style scoped>
.firstBox {
  width: 100%;
  height: 100%;
  /* background: red; */
  display: flex;
  flex-direction: column;
}
.top {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 40px;
  flex-shrink: 0;
}
.tip {
  position: absolute;
  left: 10px;
  bottom: 15px;
  color: #abaeb3;
  font-size: 12px;
}
.topItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abaeb3;
}
.topItemNumBox {
  width: 18px;
  height: 18px;
  background: #c4c7cc;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m10R {
  margin-right: 10px;
}
.m5R {
  margin-right: 5px;
}
.activeColor {
  color: #4db4d4;
}
.activeBg {
  background: #4db4d4;
}
.greyColor {
  color: #dcdfe6;
}
.firstContent {
  width: 100%;
  position: absolute;
  left: 0;
  top: 78px;
  bottom: 69px;
  overflow-y: auto;
}
.contentNameBox {
  padding: 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid #dcdfe6;
}
.uploadBox {
  padding: 10px;
  width: calc(100% - 20px);
}
.contentName {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #313233;
  margin-bottom: 10px;
}
.contentInput {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
}
.contentInput:focus {
  border: none;
}
.category-box {
  padding: 15px 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid #dcdfe6;
}
.category-title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #313233;
  margin-bottom: 10px;
}
.category-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.category-item {
  display: inline-block;
  padding: 9px;
  min-width: 46px;
  text-align: center;
  margin: 10px 10px 0 0;
  color: #313233;
  font-size: 14px;
  background: #f5f7fa;
  border-radius: 5px;
}
.category-item.active {
  color: #4db4d4;
}
.uploadDesc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abaeb3;
}
.img_upload {
  width: 32px;
  height: 32px;
}
.remark {
  font-size: 8px;
  color: #7a7c80;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.uploadComponent {
  margin-top: 20px;
  width: 100%;
  display: flex;
  height: 80px;
  background: #f5f7fa;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.uploadBoxImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoBox {
  width: 150px;
  height: 80px;
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
}
.videoItem {
  width: 100%;
  height: 100%;
}
.closeIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.list {
  width: calc(100% - 20px);
  padding: 0 10px;
  height: 130px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}
.nextBtnBox {
  /* width: calc(100%-20px); */
  width: 100%;
  /* bottom: 68px; */
  height: 68px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-top: 1px solid #dcdfe6;
  background: #fff;
  flex-shrink: 0;
}
.nextBtn {
  width: 70px;
  height: 28px;
  background: rgba(77, 180, 212, 0.5);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.activeBlueBg {
  background: #4db4d4;
}
.pointer {
  cursor: pointer;
}
.content-two .upload-demo {
  margin: 20px 0;
}
.content .upload-demo /deep/ .el-upload {
  width: 100%;
}
.content .upload-demo {
  margin: 20px 0;
}
.content .img_require p {
  font-size: 12px;
  color: #abaeb3;
  line-height: 20px;
}
.upload-demo {
  width: 100%;
}
.upload-demo /deep/ .el-upload {
  width: 98%;
}
.upload-demo /deep/ .el-upload {
  width: 100%;
}
.upload-demo /deep/ .el-upload-dragger {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border: none;
  border-radius: 2px;
}
.upload-demo img {
  width: 32px;
  height: 32px;
}
.upload-demo .el-upload__text {
  line-height: 32px;
  font-size: 8px;
  color: #7a7c80;
}
.content-two .upload-demo img {
  width: 32px;
  height: 32px;
}
.content-two .upload-demo .el-upload__text {
  font-size: 8px;
  color: #7a7c80;
}
.uploadImages {
  width: 50px;
  height: 50px;
}
.video-cover {
  border-top: 1px solid #dcdfe6;
  padding-top: 10px;
  padding-left: 10px;
}
.video-cover /deep/ .el-upload {
  text-align: left;
}
.uploadArlImages {
  width: 100px;
  height: auto;
  border: 1px solid #dcdfe6;
}
.video-cover-text {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
</style>